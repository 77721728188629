import { datadogRum } from "@datadog/browser-rum";
import { config } from "config";

datadogRum.init({
  applicationId: config.ddApplicationId,
  clientToken: config.ddClientToken,
  site: "datadoghq.com",
  service: "freeway",
  env: config.environment,
  version: config.version,
  sampleRate: 100,
  premiumSampleRate: 0,
  trackInteractions: true,
});
