import { DrivewayTheme } from "@driveway/react";
import { CssBaseline, Theme } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import { ReactNode } from "react";
import breakpoints from "./breakpoints";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={DrivewayTheme}>
        <MUIThemeProvider
          theme={(theme: Theme) =>
            createTheme({
              ...theme,
              breakpoints,
              typography: {
                fontFamily:
                  "Circular, -apple-system, BlinkMacSystemFont, Segoe UI, " +
                  "Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, " +
                  "Segoe UI Emoji, Segoe UI Symbol",
                htmlFontSize: 14,
              },
              components: {
                ...theme.components,
                MuiLink: {
                  styleOverrides: {
                    root: {
                      textUnderlineOffset: "5px",
                      color: theme.colors.primary[500],
                      textDecorationColor: theme.colors.primary[200],
                      textDecorationThickness: "1px",
                      fontWeight: 600,
                      "&:focus ": {
                        outline: "none",
                      },
                      "&.focus-visible": {
                        position: "relative",
                      },
                      "&.focus-visible:before": {
                        content: `" "`,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "80%",
                        padding: "4px",
                        boxShadow: `0 0 0 4px ${theme.colors.primary[200]}`,
                        borderRadius: "1px",
                        boxSizing: "content-box",
                        transform: "translate(-4px, -2px)",
                      },
                      "&:active:before": {
                        boxShadow: "none ",
                      },
                      "&:hover": {
                        color: theme.colors.primary[700],
                        textDecorationColor: theme.colors.primary[300],
                      },
                      "&:active": {
                        color: theme.colors.primary[900],
                        textDecorationColor: theme.colors.primary[500],
                      },
                    },
                  },
                },
                MuiDivider: {
                  styleOverrides: {
                    root: {
                      borderRadius: "2px",
                    },
                  },
                },
                MuiChip: {
                  styleOverrides: {
                    label: {
                      fontWeight: 600,
                    },
                    labelSmall: {
                      fontSize: theme.typography.pxToRem(12),
                      padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
                    },
                    labelMedium: {
                      fontSize: theme.typography.pxToRem(14),
                      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
                    },
                    colorInfo: {
                      color: theme.colors.neutral[800],
                      backgroundColor: theme.colors.primary[100],
                    },
                  },
                },
              },
            })
          }
        >
          <CssBaseline />
          {children}
        </MUIThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
