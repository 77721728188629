import "./MuiClassNameSetup";
import "simplebar/src/simplebar.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// ----------------------------------------------------------------------

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
