import { Toast } from "@driveway/react";
import {
  getNextToastAtom,
  removeNextToastAtom,
} from "freeway/state/ToastState";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";

export const toastDuration: number = 4000;

const ToastHandler = () => {
  const [nextToast] = useAtom(getNextToastAtom);
  const [, removeNextToast] = useAtom(removeNextToastAtom);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  useEffect(() => {
    if (nextToast === undefined) {
      return;
    }
    setToastOpen(true);
  }, [nextToast]);

  return (
    <>
      <Toast
        isOpen={toastOpen}
        setIsOpen={() => {
          setToastOpen(false);
          setTimeout(() => {
            removeNextToast();
          }, 500);
        }}
        title={nextToast?.title}
        actionBtnProps={{
          text: nextToast?.actionButtonText,
          onClick: () => {
            const toastUrl = new URL(`${nextToast?.actionButtonUrl}`);
            window.open(toastUrl, "_blank");
          },
        }}
        message={nextToast?.message}
        alertType={nextToast?.alertType}
        onScreenDuration={toastDuration}
        data-testid="toast"
      />
    </>
  );
};

export default ToastHandler;
