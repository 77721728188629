import Paths from "./routes/paths"; // Requires relative since this is used by the server with a conflicting baseUrl

const getEnv = (key: string): string => {
  const globalWindow = global.window || {};
  const windowEnv = globalWindow.__env || {};
  return windowEnv[key] || process.env[key] || "";
};

export const config = {
  pathAfterLogin: Paths.App,
  environment: getEnv("REACT_APP_ENV") || "prod",
  authClientID: getEnv("REACT_APP_AUTH_CLIENT_ID"),
  authAuthorityID: getEnv("REACT_APP_AUTH_AUTHORITY_ID"),
  baseURL: getEnv("REACT_APP_BASE_URL"),
  exampleEndpointApiPath: getEnv("REACT_APP_EXAMPLE_ENDPOINT_API_PATH"),
  version: process.env.REACT_APP_VERSION || "",
  optimizelyKey: getEnv("REACT_APP_OPTIMIZELY_KEY"),
  authorizedGroup: getEnv("REACT_APP_AUTHORIZED_GROUP"),
  ddApplicationId: getEnv("REACT_APP_DD_APPLICATION_ID"),
  ddClientToken: getEnv("REACT_APP_DD_CLIENT_TOKEN"),
  sfUrl: getEnv("REACT_APP_SF_URL"),
  contentfulEnvironment: getEnv("REACT_APP_CONTENTFUL_ENVIRONMENT"),
  contentfulSpace: getEnv("REACT_APP_CONTENTFUL_SPACE"),
  contentfulDeliveryToken: getEnv("REACT_APP_CONTENTFUL_TOKEN"),
  contentfulPreviewToken: getEnv("REACT_APP_CONTENTFUL_PREVIEW_TOKEN"),
  optimizelyDomain: getEnv("REACT_APP_OPTIMIZELY_DOMAIN"),
};
