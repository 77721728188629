import Copy from "freeway/copy";
import Paths from "routes/paths";
import { getIconComponent } from "utils/icons";

// ----------------------------------------------------------------------

const ICONS = {
  home: getIconComponent("home"),
  announcements: getIconComponent("zap"),
  vehicleValuation: getIconComponent("money"),
  autoFinderPlus: getIconComponent("keys"),
};

const navConfig = [
  {
    subheader: "",
    items: [
      { title: Copy.navigation.home, path: Paths.Home, icon: ICONS.home },
    ],
  },
];

export default navConfig;
