import { BoxProps } from "@mui/material";
import FreewayLogoIcon from "assets/freeway_logo_icon";
import { Link as RouterLink } from "react-router-dom";
import Paths from "routes/paths";

interface Props extends BoxProps {
  disabledLink?: boolean;
  fillColor?: string;
}

export default function Logo({ disabledLink = false, sx, fillColor }: Props) {
  if (disabledLink) {
    return <FreewayLogoIcon sx={sx} fillColor={fillColor} />;
  }

  return (
    <RouterLink to={Paths.Home}>
      <FreewayLogoIcon sx={sx} fillColor={fillColor} />
    </RouterLink>
  );
}
