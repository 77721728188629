import { AuthFeedback } from "core/components/auth";

export default function Unauthorized() {
  return (
    <AuthFeedback
      title="Unauthorized"
      subtext="Uh-oh! Looks like your account access needs to be upgraded to view this page."
      buttons={[
        {
          text: "Request Access",
          href: "https://lithia.service-now.com/rrc?id=sc_cat_item&table=sc_cat_item&sys_id=15c810761b0559d0a11f1131b24bcb29",
        },
      ]}
    />
  );
}
