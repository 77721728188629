import { Box, Divider, Drawer, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFeature } from "@optimizely/react-sdk";
import Logo from "component-lib/Logo";
import { NavSectionVertical } from "component-lib/nav-section";
import Scrollbar from "component-lib/Scrollbar";
import Copy from "freeway/copy";
import {
  afpEnabled,
  iicoEnabled,
  phase3AnnouancementsEnabled,
} from "freeway/featureFlags";
import useResponsive from "hooks/useResponsive";
import Paths from "routes/paths";
import { NAVBAR } from "theme/theme-config";
import { getIconComponent } from "utils/icons";
import NavbarAccount from "./NavbarAccount";
import navConfig from "./NavConfig";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  avatar: React.ReactNode;
  jobTitle: string;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  avatar,
  jobTitle,
}: Props) {
  const isDesktop = useResponsive("up", "xs");
  const [announcementsListEnabled] = useFeature(phase3AnnouancementsEnabled);
  const [vehicleValuationEnabled] = useFeature(iicoEnabled);
  const [autoFinderPlusEnabled] = useFeature(afpEnabled);

  const announcementNav = {
    title: Copy.navigation.announcements,
    path: Paths.Announcements,
    icon: getIconComponent("zap"),
  };

  let navConfigToUse = announcementsListEnabled
    ? [{ ...navConfig[0], items: [...navConfig[0].items, announcementNav] }]
    : navConfig;

  const vehicleValuationNav = {
    title: Copy.navigation.iico,
    path: Paths.VehicleValuation,
    icon: getIconComponent("money"),
  };

  const autoFinderPlusNav = {
    title: Copy.navigation.afp,
    path: Paths.AutoFinderPlus,
    icon: getIconComponent("keys"),
  };

  navConfigToUse = vehicleValuationEnabled
    ? [
        {
          ...navConfigToUse[0],
          items: [...navConfigToUse[0].items, vehicleValuationNav],
        },
      ]
    : navConfigToUse;

  navConfigToUse = autoFinderPlusEnabled
    ? [
        {
          ...navConfigToUse[0],
          items: [...navConfigToUse[0].items, autoFinderPlusNav],
        },
      ]
    : navConfigToUse;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          py: 4,
          flexShrink: 0,
        }}
      >
        <Stack
          direction="column"
          spacing={4}
          alignItems="center"
          divider={
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: "#B5CCF1", opacity: 0.2 }}
            />
          }
          justifyContent="center"
        >
          <Logo fillColor="white" />
          <NavbarAccount avatar={avatar} jobTitle={jobTitle} />
          <NavSectionVertical
            navConfig={navConfigToUse}
            isCollapse={false}
            sx={{ width: "100%", px: 3 }}
          />
        </Stack>
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: NAVBAR.DASHBOARD_WIDTH,
        },
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              bgcolor: "#082048",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
