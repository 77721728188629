import {
  AccountInfo,
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { IMsalContext, useMsal as realUseMsal } from "@azure/msal-react";
import useLocalStorage from "hooks/useLocalStorage";

const instance = {
  addEventCallback: () => {},
} as unknown as IPublicClientApplication;
const accounts: AccountInfo[] = [];

const useMockMsal: () => IMsalContext = () => {
  const [inProgress] = useLocalStorage("inProgress", InteractionStatus.None);
  return {
    inProgress,
    instance,
    accounts,
  } as IMsalContext;
};

export const useMsal = process.env["REACT_APP_MOCK_FOR_INTEGRATION"]
  ? useMockMsal
  : realUseMsal;
