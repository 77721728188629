import LoadingScreen from "component-lib/LoadingScreen";
import { config } from "config";
import DashboardLayoutAnnouncements from "core/components/dashboard/DashboardLayoutAnnouncements";
import Unauthorized from "core/pages/Unauthorized";
import CoreRoutes from "core/routes";
import FreewayRoutes from "freeway/routes";
import GroupBasedGuard from "guards/GroupBasedGuard";
import AuthOptimizelyProvider from "providers/AuthOptimizelyProvider";
import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Paths from "routes/paths";

// ----------------------------------------------------------------------
// Read more here on the new react-router changes:
//
// https://reactrouter.com/docs/en/v6
// ----------------------------------------------------------------------

const Router = () => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {/* ----------------------------------------------------------------------
         /* These routes are nested under `Paths.Home` - so they are protected by
         /* `AuthGuard`. Some routes only require authentication, others require specific
         /* roles protected by `RoleBasedGuard`.
         /*
         /* The default `index` for `Paths.Dashboard` is to redirect the user to
         /* `Paths.App`.
         /* ----------------------------------------------------------------------
          */}
        <Route
          key="root"
          path={Paths.Base}
          element={
            <GroupBasedGuard
              accessibleGroups={[config.authorizedGroup]}
              noGroupsElement={<Unauthorized />}
            >
              <AuthOptimizelyProvider>
                <DashboardLayoutAnnouncements />
              </AuthOptimizelyProvider>
            </GroupBasedGuard>
          }
        >
          {FreewayRoutes}

          {/* This is the default route for `Paths.Home` - send the user to the
           /* primary App, if they are logged in. Otherwise, send them to log in.
            */}
          <Route
            key="app-index"
            index={true}
            element={<Navigate to={Paths.Home} replace />}
          />
        </Route>

        {/* Core routes for login and error pages */}
        {CoreRoutes}
      </Routes>
    </Suspense>
  );
};

export default Router;
