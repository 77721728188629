import { loginRedirectRequestWithState } from "authConfig";
import AuthFeedback from "core/components/auth/AuthFeedback";
import { useMsal } from "msal-react";
import { useCallback } from "react";

export default function Login() {
  const { instance } = useMsal();

  // get preview params to send through state via login auth object
  const previewParam =
    new URLSearchParams(window.location.search).get("preview") === "all";

  const doLogin = useCallback(() => {
    instance.loginRedirect(loginRedirectRequestWithState(previewParam));
  }, [instance, previewParam]);

  return (
    <AuthFeedback
      title="Log In"
      subtext="Log in to view this page."
      buttons={[
        { text: "Log In", onClick: doLogin },
        {
          text: "Need help? Let us know!",
          href: "https://lithia.service-now.com/rrc?id=sc_cat_item&table=sc_cat_item&sys_id=15c810761b0559d0a11f1131b24bcb29",
        },
      ]}
    />
  );
}
