import {
  AlertTriangleIcon,
  BookOpenIcon,
  ClockIcon,
  DollarSignIcon,
  Edit2Icon,
  HomeIcon,
  KeysIcon,
  LinkExternalIcon,
  PlayCircleIcon,
  ToolIcon,
  ZapIcon,
} from "@driveway/react";

export type IconSlug =
  | "alertTriangle"
  | "bookOpen"
  | "edit2"
  | "playCircle"
  | "tool"
  | "zap"
  | "linkExternal"
  | "home"
  | "money"
  | "clock"
  | "keys";

export function getIconComponent(slug: IconSlug) {
  switch (slug) {
    case "alertTriangle":
      return <AlertTriangleIcon />;
    case "bookOpen":
      return <BookOpenIcon />;
    case "edit2":
      return <Edit2Icon />;
    case "playCircle":
      return <PlayCircleIcon />;
    case "tool":
      return <ToolIcon />;
    case "linkExternal":
      return <LinkExternalIcon />;
    case "zap":
      return <ZapIcon />;
    case "home":
      return <HomeIcon />;
    case "money":
      return <DollarSignIcon />;
    case "clock":
      return <ClockIcon />;
    case "keys":
      return <KeysIcon />;
    default:
      return "Unknown icon";
  }
}
