import "utils/datadog";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { msalConfig } from "authConfig";
import MotionLazyContainer from "component-lib/animate/MotionLazyContainer";
import ErrorBoundary from "component-lib/ErrorBoundary";
import NotistackProvider from "component-lib/NotistackProvider";
import { ProgressBarStyle } from "component-lib/ProgressBar";
import ScrollToTop from "component-lib/ScrollToTop";
import AuthRedirectHandler from "core/components/auth/AuthRedirectHandler";
import ToastHandler from "freeway/components/app/ToastHandler";
import AuthProvider from "providers/AuthProvider";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Router from "routes";
import ThemeProvider from "theme";
import { getOptimizelyClient } from "utils/optimizely";

// ----------------------------------------------------------------------

const msalInstance = new PublicClientApplication(msalConfig);

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <AuthProvider>
            <OptimizelyProvider
              optimizely={getOptimizelyClient({
                homeAccountId: "notLoggedIn",
              } as any)}
            >
              <MsalProvider instance={msalInstance}>
                <AuthRedirectHandler />
                <ThemeProvider>
                  <NotistackProvider>
                    <MotionLazyContainer>
                      <ErrorBoundary>
                        <ProgressBarStyle />
                        <ScrollToTop />
                        <Router />
                        <ToastHandler />
                      </ErrorBoundary>
                    </MotionLazyContainer>
                  </NotistackProvider>
                </ThemeProvider>
              </MsalProvider>
            </OptimizelyProvider>
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  );
}
