import { Avatar as MUIAvatar, AvatarProps } from "@mui/material";
import { forwardRef } from "react";

// ----------------------------------------------------------------------

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ children, sx, ...other }, ref) => {
    return (
      <MUIAvatar ref={ref} sx={sx} {...other}>
        {children}
      </MUIAvatar>
    );
  },
);

export default Avatar;
