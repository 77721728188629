import { InteractionStatus } from "@azure/msal-browser";
import { Alert, AlertTitle, Container } from "@mui/material";
import LoadingScreen from "component-lib/LoadingScreen";
import useAuth from "hooks/useAuth";
import useMsalGroups from "hooks/useMsalGroups";
import { useIsAuthenticated, useMsal } from "msal-react";
import { ReactNode, useMemo } from "react";
import { Navigate } from "react-router";
import Paths from "routes/paths";

// ----------------------------------------------------------------------
// This guard should wrap any pages that requires the user to first be
// authenticated and logged in using their Azure SSO login AND to have the
// correct AAD group membership.
//
// It will show a loading state while it checks for authentication and groups,
// then redirects the user to log in if they are not logged in or are not a
// member of one of the requested groups.
//
// You can optionally pass in custom JSX elements to `noGroupsElement` to render
// to the user in the event they do not belong to the correct AAD group.
// ----------------------------------------------------------------------

type GroupBasedGuardProp = {
  accessibleGroups: string[];
  children: ReactNode;
  noGroupsElement?: ReactNode;
};

export default function GroupBasedGuard({
  accessibleGroups,
  children,
  noGroupsElement,
}: GroupBasedGuardProp) {
  const groups = useMsalGroups();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { isLoading, currentState } = useAuth();

  const hasGroups = useMemo(
    () => accessibleGroups.some((group) => groups.has(group)),
    [accessibleGroups, groups],
  );

  if (inProgress !== InteractionStatus.None || isLoading) {
    return <LoadingScreen />;
  } else if (
    !isAuthenticated &&
    (currentState === "SILENT_FAILURE" || currentState === "REDIRECT_FAILURE")
  ) {
    // pass along params, right now used for CMS preview functionality
    return <Navigate to={`${Paths.Login}${window.location.search}`} />;
  } else if (!hasGroups) {
    if (noGroupsElement) {
      return <>{noGroupsElement}</>;
    } else {
      return (
        <Container>
          <Alert severity="error">
            <AlertTitle>Permission Denied</AlertTitle>
            You do not have permission to access this page. Contact your
            administrator.
          </Alert>
        </Container>
      );
    }
  }

  return <>{children}</>;
}
