import { Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "component-lib/typography/Typography";
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  color: "white",
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  avatar: React.ReactNode;
  jobTitle: string;
};

export default function NavbarAccount({ avatar, jobTitle }: Props) {
  const { accountInfo } = useAuth();

  return (
    <Link underline="none" color="inherit">
      <RootStyle>
        {avatar}
        <Box
          sx={{
            width: "170px",
            color: "white",
            ml: 2,
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Typography
            variant="bodySmallBold"
            noWrap
            sx={{ "&&": { fontWeight: "500" } }}
          >
            {accountInfo.name}
          </Typography>
          <Typography variant="bodySmall" noWrap>
            {jobTitle}
          </Typography>
          <Typography variant="microcopy">{accountInfo.username}</Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
