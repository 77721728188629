import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import Paths from "routes/paths";

// These example pages and routes can be deleted once they are no longer needed
// as reference
const LandingPage = lazy(() => import("./pages/LandingPage"));
const AnnouncementsPageGuard = lazy(
  () => import("../freeway/components/app/AnnouncementsPageGuard"),
);
const AnnouncementDetailPage = lazy(
  () => import("./pages/AnnouncementDetailPage"),
);

const VehicleValuationPage = lazy(
  () => import("./pages/VehicleValuationTool/VehicleValuationToolPage"),
);

const AutoFinderPlusPage = lazy(
  () => import("./pages/AutoFinderPlus/AutoFinderPlusPage"),
);

const FreewayRoutes = [
  <Route key="app" path={Paths.Home} element={<LandingPage />} />,
  <Route
    key="announcements"
    path={Paths.Announcements}
    element={<AnnouncementsPageGuard />}
  />,
  <Route
    key="announcement"
    path={Paths.Announcement}
    element={<AnnouncementDetailPage />}
  />,
  <Route
    key="vehiclevaluation"
    path={Paths.VehicleValuation}
    element={<VehicleValuationPage />}
  />,
  <Route
    key="autofinderplus"
    path={Paths.AutoFinderPlus}
    element={<AutoFinderPlusPage />}
  />,
  <Route
    key="oldHome"
    path={Paths.OldHome}
    element={<Navigate to={Paths.Home} replace />}
  />,
];

export default FreewayRoutes;
