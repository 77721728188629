import { styled } from "@mui/material/styles";
import { graphApiTokenSilentRequest } from "authConfig";
import MyAvatar from "component-lib/MyAvatar";
import { userNameAtom, userTitleAtom } from "freeway/state/AuthState";
import useAuth from "hooks/useAuth";
import { useAtom } from "jotai";
import { useMsal } from "msal-react";
import { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { NAVBAR } from "theme/theme-config";
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";

const MainStyle = styled("main")(() => ({
  paddingLeft: `${NAVBAR.BASE_WIDTH}px`,
}));

export default function DashboardLayoutAnnouncements() {
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => setOpen(false), []);
  const { instance } = useMsal();
  const { accountInfo } = useAuth();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [, setUserName] = useAtom(userNameAtom);
  const [userTitle, setUserTitle] = useAtom(userTitleAtom);

  useEffect(() => {
    const loadAvatar = async () => {
      const headers = new Headers();
      const url = `https://graph.microsoft.com/v1.0/me/photo/$value`;
      headers.append("Content-Type", "image/jpg");
      const fetchOptions = {
        method: "GET",
        headers,
      };
      setUserName(accountInfo.name || "");
      await instance
        .acquireTokenSilent({
          ...graphApiTokenSilentRequest,
          account: accountInfo,
        })
        .then((result) => {
          headers.append("Authorization", `Bearer ${result.accessToken}`);
        })
        .then(() => fetch(url, fetchOptions))
        .then((response) => response.blob())
        .then((avatarBlob) => URL.createObjectURL(avatarBlob))
        .then((avatarUrl) => setAvatarUrl(avatarUrl));
    };
    /*
    CONTEXT:
    If we launch the app with this parameter, we will bypass the normal
    authentication logic and blindly trust the browsers storage state.
    This storage state will contain mocked data (read: fake) which
    we don't want to throw against Microsoft's APIs to fetch the avatar
    or job title of this fake user. Without this if block, a lot
    of null values will get passed to places which doesnt account for nulls.
    */
    if (!process.env["REACT_APP_MOCK_FOR_INTEGRATION"]) {
      loadAvatar();
    }
  }, [accountInfo, instance, setUserName]);

  useEffect(() => {
    const loadJobTitle = async () => {
      const headers = new Headers();
      const url = `https://graph.microsoft.com/v1.0/me`;
      headers.append("Content-Type", "application/json");
      const fetchOptions = {
        method: "GET",
        headers,
      };

      await instance
        .acquireTokenSilent({
          ...graphApiTokenSilentRequest,
          account: accountInfo,
        })
        .then((result) => {
          headers.append("Authorization", `Bearer ${result.accessToken}`);
        })
        .then(() => fetch(url, fetchOptions))
        .then((response) => response.json())
        .then(({ jobTitle }) => {
          setUserTitle(jobTitle);
        });
    };
    // See above comment for context
    if (!process.env["REACT_APP_MOCK_FOR_INTEGRATION"]) {
      loadJobTitle();
    }
  }, [accountInfo, instance, setUserTitle]);

  const avatar = <MyAvatar avatarUrl={avatarUrl} name={accountInfo.name} />;

  return (
    <>
      <DashboardHeader />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={handleClose}
        avatar={avatar}
        jobTitle={userTitle}
      />

      <MainStyle
        sx={{
          pb: 8,
        }}
      >
        <Outlet />
      </MainStyle>
    </>
  );
}
