import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const typographyStyles = (theme: Theme) => ({
  hero: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(41),
    lineHeight: theme.typography.pxToRem(47),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(57),
      lineHeight: theme.typography.pxToRem(68),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(76),
      lineHeight: theme.typography.pxToRem(87),
    },
  },
  h1: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(44),
    lineHeight: theme.typography.pxToRem(53),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: theme.typography.pxToRem(72),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(60),
      lineHeight: theme.typography.pxToRem(72),
    },
  },
  h2: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(36),
    lineHeight: theme.typography.pxToRem(45),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(52),
      lineHeight: theme.typography.pxToRem(65),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(52),
      lineHeight: theme.typography.pxToRem(65),
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(30),
    lineHeight: theme.typography.pxToRem(39),
    letterSpacing: "-0.6px",
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(44),
      lineHeight: theme.typography.pxToRem(57),
      letterSpacing: "-0.7px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(44),
      lineHeight: theme.typography.pxToRem(57),
      letterSpacing: "-1.2px",
    },
  },
  h4: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(35),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(36),
      lineHeight: theme.typography.pxToRem(46),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(36),
      lineHeight: theme.typography.pxToRem(46),
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(31),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(38),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(38),
    },
  },
  h6: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
  },
  subtitle: {
    fontWeight: 400,
    letterSpacing: "-0.5px",
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(23),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(19),
      lineHeight: theme.typography.pxToRem(25),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(26),
    },
  },
  bodyLead: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(27),
    },
  },
  bodyLeadBold: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(27),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(27),
    },
  },
  body: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  bodyBold: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  bodySmall: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(21),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),
    },
  },
  bodySmallBold: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(21),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),
    },
  },
  microcopy: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(19),
    },
  },
  microcopyBold: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(19),
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(19),
    },
  },
  disclaimer: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(18),
    letterSpacing: "-.1px",
    color: theme.colors.neutral[700],
    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      letterSpacing: "-.14px",
    },
  },
  overlineSmall: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(19),
    letterSpacing: "1px",
    textTransform: "uppercase" as const,
  },
  overlineLarge: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: "1px",
    textTransform: "uppercase" as const,
  },
});

const useStyles = makeStyles({ name: "MuiFWTypography" })(typographyStyles);

export default useStyles;
