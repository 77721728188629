import Login from "core/pages/Login";
import GuestGuard from "guards/GuestGuard";
import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import Paths from "routes/paths";

const NotFound = lazy(() => import("core/pages/NotFound"));
const Error = lazy(() => import("core/pages/Error"));
const Unauthorized = lazy(() => import("core/pages/Unauthorized"));

const CoreRoutes = [
  <Route
    key="login"
    path={Paths.Login}
    element={
      <GuestGuard>
        <Login />
      </GuestGuard>
    }
  />,
  <Route key="404" path={Paths.NotFound} element={<NotFound />} />,
  <Route key="error" path={Paths.Error} element={<Error />} />,
  <Route
    key="not-found"
    path="*"
    element={<Navigate to={Paths.NotFound} replace />}
  />,
  <Route
    key="Unauthorized"
    path={Paths.Unauthorized}
    element={<Unauthorized />}
  />,
];

export default CoreRoutes;
