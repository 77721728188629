import { Button } from "@driveway/react";
import { Container, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import crmBg from "assets/crm_bg.svg";
import RedCar from "assets/red_car.svg";
import Page from "component-lib/Page";
import Typography from "component-lib/typography/Typography";
import AuthHeader from "./AuthHeader";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 825,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  padding: theme.spacing(12, 0),
}));

const ImgStyle = styled("img")(({ theme }) => ({
  width: 825,
  marginTop: -360,
  [theme.breakpoints.down("xl")]: {
    width: 660,
    marginTop: -300,
  },
  [theme.breakpoints.down("lg")]: {
    width: 488,
    marginTop: -260,
  },
}));

// ----------------------------------------------------------------------

type ActionButton = {
  text: string;
  onClick: () => void;
};

type LinkButton = {
  text: string;
  href: string;
};

type AuthFeedbackProps = {
  subtext: string;
  buttons: Array<ActionButton | LinkButton>;
  title: string;
};

export default function AuthFeedback({
  subtext,
  buttons,
  title,
}: AuthFeedbackProps) {
  return (
    <Page title={title}>
      <AuthHeader />
      <Container
        maxWidth={false}
        sx={{
          height: 447,
          backgroundColor: "#FAFAFA",
          backgroundImage: `url(${crmBg})`,
          mx: 0,
        }}
      ></Container>
      <Container sx={{ height: "50vh" }}>
        <ContentStyle>
          <ImgStyle src={RedCar} alt="Red Car" />
          <Typography variant="h1" sx={{ mb: 1.5, mt: 4 }}>
            Your tools are waiting!
          </Typography>
          <Container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              mx: 3,
            }}
          >
            <Typography mb={3} variant="bodyLead">
              {subtext}
            </Typography>
            <Stack direction="row" spacing={6}>
              {buttons.map(({ text, ...rest }) => (
                <Button key={text} variant="tertiary" {...rest}>
                  {text}
                </Button>
              ))}
            </Stack>
          </Container>
        </ContentStyle>
      </Container>
    </Page>
  );
}
