import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "component-lib/Logo";
import AccountStack from "core/components/dashboard/header/AccountStack";
import useOffSetTop from "hooks/useOffSetTop";
import { HEADER } from "theme/theme-config";

// ----------------------------------------------------------------------

type RootStyleProps = {
  isOffset: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isOffset",
})<RootStyleProps>(({ theme }) => ({
  position: "absolute",
  backgroundColor: "transparent",
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

export default function AuthHeader() {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);

  return (
    <RootStyle isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: "100% !important",
          mt: 2,
          px: { lg: 6 },
          alignItems: "flex-start",
        }}
      >
        <Logo sx={{ mr: 2.5 }} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <AccountStack />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
