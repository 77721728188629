import { ErrorInfo, ReactNode } from "react";
import React from "react";
import ErrorPage from "../core/pages/Error";

type Props = {
  children?: ReactNode;
};

type State = {
  error: null | Error;
  errorInfo: null | ErrorInfo;
};

const initialState: State = {
  error: null,
  errorInfo: null,
};

class ErrorBoundary extends React.Component<Props, State> {
  state = initialState;

  static getDerivedStateFromError(error: Error): State {
    return {
      error,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error.toString(), errorInfo.componentStack);
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
export type { Props as ErrorBoundaryProps, State as ErrorBoundaryState };
