import { ToastType } from "freeway/models/Toast";
import { atom } from "jotai";

const addToast = (toasts: ToastType[], toast: ToastType): ToastType[] => {
  return [...toasts, toast];
};

export const toastsAtom = atom<ToastType[]>([]);
export const addToastAtom = atom(
  () => "",
  (get, set, toast: ToastType) => {
    set(toastsAtom, addToast(get(toastsAtom), toast));
  },
);
export const removeNextToastAtom = atom(
  () => "",
  (get, set) => {
    let toasts = [...get(toastsAtom)];
    toasts.shift();
    set(toastsAtom, toasts);
  },
);
export const getNextToastAtom = atom((get) => {
  let toast = get(toastsAtom);
  return toast !== undefined ? toast[0] : undefined;
});
