import { ArrowLeftIcon, Button } from "@driveway/react";
import { AppBar, Box, Divider, Grid, Stack, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFeature } from "@optimizely/react-sdk";
import { phase3AnnouancementsEnabled } from "freeway/featureFlags";
import useOffSetTop from "hooks/useOffSetTop";
import { useLocation, useMatch } from "react-router";
import { useNavigate } from "react-router-dom";
import Paths from "routes/paths";
import { HEADER, NAVBAR } from "theme/theme-config";
import AccountStack from "./AccountStack";

// ----------------------------------------------------------------------

type RootStyleProps = {
  isOffset: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isOffset",
})<RootStyleProps>(({ theme }) => ({
  position: "relative",
  backgroundColor: "transparent",
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

export default function DashboardHeader() {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);
  const location = useLocation();
  const [announcementsEnabled] = useFeature(phase3AnnouancementsEnabled);
  const isAnnouncementsDetailPage = useMatch(Paths.Announcement);
  const navigate = useNavigate();
  const isAnnouncementsListPage = location.pathname === Paths.Announcements;

  return (
    <RootStyle isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: "100% !important",
          mt: 2,
          px: { lg: 6 },
          alignItems: "flex-start",
        }}
      >
        <Stack
          direction="column"
          sx={{
            width: 1,
            ml: `${NAVBAR.BASE_WIDTH}px`,
          }}
          spacing={1}
        >
          <Grid container>
            {isAnnouncementsListPage && (
              <Button
                startIcon={<ArrowLeftIcon />}
                variant="tertiary"
                onClick={() => navigate(Paths.Home)}
                size="xsmall"
              >
                Back Home
              </Button>
            )}
            {isAnnouncementsDetailPage && announcementsEnabled && (
              <Button
                startIcon={<ArrowLeftIcon />}
                variant="tertiary"
                onClick={() => navigate(Paths.Announcements)}
                size="xsmall"
              >
                Back to Announcements
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <AccountStack />
            </Stack>
          </Grid>
          <Divider />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
