import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  InteractionType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { datadogRum } from "@datadog/browser-rum";
import { loginRedirectRequestWithState } from "authConfig";
import { isPreviewAtom } from "freeway/state/AuthState";
import useAuth from "hooks/useAuth";
import { SetStateAction, useSetAtom } from "jotai";
import { useMsal, useMsalAuthentication } from "msal-react";
import { useEffect } from "react";

function postLogin(
  instance: IPublicClientApplication,
  loginSuccess: (account: AccountInfo) => void,
  response: AuthenticationResult,
  setIsPreview: (args_0: SetStateAction<boolean>) => void,
  previewParam: boolean,
) {
  // if preview param is present OR preview param was sent through to the auth state
  if (response.state || previewParam) {
    const hasPreview = response.state === "isPreview" || previewParam;
    // setting to be used for CMS consumption
    setIsPreview(hasPreview);
  }
  if (response.account) {
    instance.setActiveAccount(response.account);
    datadogRum.setUser(response.account);
    loginSuccess(response.account);
  }
}

export default function AuthRedirectHandler() {
  // get preview params to send through state via login auth object
  // also used in postLogin
  const previewParam =
    new URLSearchParams(window.location.search).get("preview") === "all";
  const setIsPreview = useSetAtom(isPreviewAtom);

  const { instance, accounts } = useMsal();
  const { loginSuccess, loginFailure, setCurrentState } = useAuth();
  const { result, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRedirectRequestWithState(previewParam),
  );

  useEffect(() => {
    if (result) {
      setCurrentState("SILENT_SUCCESS");
      postLogin(instance, loginSuccess, result, setIsPreview, previewParam);
    } else if (error) {
      setCurrentState("SILENT_FAILURE");
      loginFailure();
    }
    instance.addEventCallback((message: any) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
          if (message.interactionType === InteractionType.Redirect) {
            setCurrentState("REDIRECT_SUCCESS");
            postLogin(
              instance,
              loginSuccess,
              message.payload,
              setIsPreview,
              previewParam,
            );
          }
          break;
        case EventType.LOGIN_FAILURE:
          setCurrentState("REDIRECT_FAILURE");
          loginFailure();
          break;
        default:
      }
    });
  }, [
    accounts,
    instance,
    loginSuccess,
    result,
    error,
    loginFailure,
    setCurrentState,
    setIsPreview,
    previewParam,
  ]);

  return null;
}
