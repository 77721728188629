import { OptimizelyProvider } from "@optimizely/react-sdk";
import useAuth from "hooks/useAuth";
import { ReactNode } from "react";
import { getOptimizelyClient } from "utils/optimizely";

export default function AuthOptimizelyProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { accountInfo, isAuthenticated } = useAuth();
  if (isAuthenticated) {
    const optimizely = getOptimizelyClient(accountInfo);
    return (
      <OptimizelyProvider optimizely={optimizely}>
        {children}
      </OptimizelyProvider>
    );
  }
  return null;
}
