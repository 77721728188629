import { Button, Typography } from "@driveway/react";
import { Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { loginRedirectRequest } from "authConfig";
import useAuth from "hooks/useAuth";
import { useMsal } from "msal-react";

const ButtonStyle = styled(Button)(({ theme }) => ({
  padding: "8px 16px !important",
  marginLeft: "0px !important",
  justifyContent: "flex-start",
  borderBottom: "none",
  ".button-text": {
    color: "black",
    borderBottom: "none !important",
  },
  "&:hover": {
    "span.button-text": {
      color: theme.colors.primary[700],
    },
  },
}));

export default function AccountStack() {
  const { instance } = useMsal();
  const { accountInfo, isAuthenticated } = useAuth();

  const renderUserName = () =>
    isAuthenticated && (
      <Typography variant="microcopyBold">
        Hi {accountInfo.name?.split(" ")[0]}!
      </Typography>
    );

  const renderLogInButton = () =>
    isAuthenticated ? (
      <ButtonStyle
        size="xsmall"
        data-testid="accountLogoutButton"
        variant="tertiary"
        onClick={() => instance.logoutRedirect()}
      >
        Log Out
      </ButtonStyle>
    ) : (
      <ButtonStyle
        size="xsmall"
        data-testid="accountLoginButton"
        variant="tertiary"
        onClick={() => instance.loginRedirect(loginRedirectRequest)}
      >
        Log In
      </ButtonStyle>
    );

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
      >
        {renderUserName()}
        {renderLogInButton()}
      </Stack>
    </>
  );
}
