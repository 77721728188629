import MUITypography, {
  TypographyProps as MUITypographyProps,
} from "@mui/material/Typography";
import { ElementType, forwardRef, RefObject } from "react";
import useStyles from "./Typography.styles";

type TypographyVariantMapping =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "body1"
  | "body2"
  | "overline";

export interface ITypographyProps {
  variant:
    | "hero"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle"
    | "bodyLead"
    | "bodyLeadBold"
    | "body"
    | "bodyBold"
    | "bodySmall"
    | "bodySmallBold"
    | "microcopy"
    | "microcopyBold"
    | "overlineSmall"
    | "overlineLarge";
}

type C = ElementType;

type TypographyProps = Omit<
  MUITypographyProps<C, { component?: C }>,
  "variant"
> &
  ITypographyProps;

const Typography = forwardRef(
  ({ className, children, variant, ...props }: TypographyProps, ref) => {
    const { classes, cx } = useStyles();

    const variantMapping = {
      hero: "h1",
      h1: "h1",
      h2: "h2",
      h3: "h3",
      h4: "h4",
      h5: "h5",
      h6: "h6",
      subtitle: "subtitle1",
      bodyLead: "body1",
      bodyLeadBold: "body1",
      body: "body2",
      bodyBold: "body2",
      bodySmall: "body2",
      bodySmallBold: "body2",
      microcopy: "body2",
      microcopyBold: "body2",
      overlineSmall: "overline",
      overlineLarge: "overline",
    };

    const activeVariant = variantMapping[variant] as TypographyVariantMapping;

    return (
      <MUITypography
        ref={ref as RefObject<HTMLSpanElement>}
        className={cx(classes[variant], className)}
        variant={activeVariant}
        {...props}
      >
        {children}
      </MUITypography>
    );
  },
);
export default Typography;
