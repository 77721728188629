import { AccountInfo } from "@azure/msal-browser";
import useLocalStorage from "hooks/useLocalStorage";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useState,
} from "react";

// ----------------------------------------------------------------------

type CurrentState =
  | "SILENT_IN_PROGRESS"
  | "SILENT_SUCCESS"
  | "SILENT_FAILURE"
  | "REDIRECT_SUCCESS"
  | "REDIRECT_FAILURE";

type AuthState = {
  isLoading: boolean;
  currentState: CurrentState;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setCurrentState: Dispatch<SetStateAction<CurrentState>>;
  isAuthenticated: boolean;
  accountInfo: AccountInfo;
  loginSuccess: (accountInfo: AccountInfo) => void;
  loginFailure: () => void;
};

type AuthProviderProps = {
  initialLoadingState?: boolean;
  children: ReactNode;
};

const anonInfo: AccountInfo = {
  homeAccountId: "",
  environment: "",
  tenantId: "",
  username: "test@example.com",
  localAccountId: "",
  name: "Anon",
  idTokenClaims: undefined,
};

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <AuthProvider>.");
};

const initialState: AuthState = {
  isLoading: true,
  currentState: "SILENT_IN_PROGRESS",
  setCurrentState: stub,
  setIsLoading: stub,
  isAuthenticated: false,
  accountInfo: anonInfo,
  loginSuccess: stub,
  loginFailure: stub,
};

const AuthContext = createContext(initialState);

const AuthProvider = ({
  children,
  initialLoadingState = true,
}: AuthProviderProps) => {
  const [mockIsLoading] = useLocalStorage("isLoading", false);
  const [mockCurrentState] = useLocalStorage("currentState", "SILENT_SUCCESS");
  const [isLoading, setIsLoading] = useState(initialLoadingState);
  const [currentState, setCurrentState] =
    useState<CurrentState>("SILENT_IN_PROGRESS");
  const [isAuthenticated, setIsAuthenticated] = useLocalStorage(
    "authenticated",
    false,
  );
  const [accountInfo, setAccountInfo] = useLocalStorage("auth", anonInfo);

  const loginSuccess = useCallback(
    (accountInfo: AccountInfo) => {
      setAccountInfo(accountInfo);
      setIsAuthenticated(true);
      setIsLoading(false);
    },
    [setAccountInfo, setIsAuthenticated],
  );

  const loginFailure = useCallback(() => {
    setAccountInfo(anonInfo);
    setIsAuthenticated(false);
    setIsLoading(false);
  }, [setAccountInfo, setIsAuthenticated]);

  const mockState = process.env["REACT_APP_MOCK_FOR_INTEGRATION"]
    ? {
        isLoading: mockIsLoading,
        currentState: mockCurrentState,
      }
    : {};

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        currentState,
        setCurrentState,
        setIsLoading,
        isAuthenticated,
        accountInfo,
        loginSuccess,
        loginFailure,
        ...mockState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };
export type { AuthState };
