const breakpoints = {
  values: {
    _: 0,
    xs: 320,
    sm: 360,
    md: 768,
    lg: 1024,
    xl: 1366,
    xxl: 1921,
  },
};

export default breakpoints;
