/* eslint-disable max-len */
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

export default function FreewayLogoIcon({
  sx,
  fillColor = "black",
}: BoxProps & { fillColor?: string }) {
  return (
    <Box sx={{ width: 146, height: 41, ...sx }}>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 201 57"
        width={146}
        height={41}
      >
        <path
          d="M132.687 17.0464H126.62C126.422 17.0472 126.23 17.1148 126.075 17.2382C125.92 17.3616 125.812 17.5336 125.767 17.7264L122.153 33.2331L118.327 17.7264C118.282 17.5336 118.173 17.3616 118.018 17.2382C117.863 17.1148 117.671 17.0472 117.473 17.0464H110.713C110.518 17.0487 110.328 17.1153 110.174 17.2357C110.02 17.3561 109.91 17.5238 109.86 17.7131L106.007 33.2997L102.393 17.7397C102.348 17.5434 102.238 17.368 102.081 17.2421C101.923 17.1163 101.728 17.0473 101.527 17.0464H95.0999C94.9629 17.0468 94.8279 17.0792 94.7057 17.141C94.5834 17.2028 94.4773 17.2923 94.3957 17.4024C94.3141 17.5124 94.2593 17.64 94.2358 17.775C94.2122 17.9099 94.2205 18.0485 94.2599 18.1797L101.633 43.2997C101.69 43.4815 101.804 43.6403 101.957 43.7531C102.111 43.8658 102.296 43.9265 102.487 43.9264H109.38C109.574 43.9271 109.764 43.8633 109.918 43.7451C110.072 43.6269 110.183 43.4609 110.233 43.2731L113.953 29.0064L117.66 43.2997C117.71 43.4876 117.821 43.6536 117.975 43.7718C118.13 43.89 118.319 43.9537 118.513 43.9531H125.38C125.569 43.9546 125.754 43.8942 125.905 43.7811C126.057 43.6679 126.168 43.5082 126.22 43.3264L133.553 18.2331C133.593 18.1019 133.601 17.9633 133.577 17.8283C133.554 17.6934 133.499 17.5658 133.417 17.4557C133.336 17.3457 133.23 17.2562 133.107 17.1944C132.985 17.1326 132.85 17.1001 132.713 17.0997L132.687 17.0464Z"
          fill={fillColor}
        />
        <path
          d="M161.18 17.046H154.793C154.561 17.0494 154.339 17.1433 154.175 17.3076C154.01 17.4719 153.917 17.6937 153.913 17.926V19.2593C153.271 18.7202 152.574 18.2509 151.833 17.8593C150.187 17.0052 148.353 16.5788 146.5 16.6193C144.293 16.5958 142.123 17.1867 140.233 18.326C138.314 19.5359 136.771 21.2586 135.78 23.2993C134.678 25.5183 134.125 27.9691 134.166 30.446C134.123 32.9488 134.676 35.4261 135.78 37.6727C136.779 39.6935 138.315 41.4003 140.22 42.606C142.088 43.7806 144.253 44.3959 146.46 44.3793C148.315 44.406 150.148 43.9707 151.793 43.1127C152.535 42.7166 153.232 42.2428 153.873 41.6993V43.0327C153.877 43.265 153.97 43.4868 154.135 43.6511C154.299 43.8154 154.521 43.9092 154.753 43.9127H161.14C161.372 43.9092 161.594 43.8154 161.758 43.6511C161.923 43.4868 162.016 43.265 162.02 43.0327V17.966C162.027 17.7335 161.944 17.5072 161.787 17.3354C161.63 17.1636 161.412 17.0598 161.18 17.046ZM153.113 33.966C152.638 34.8836 151.906 35.6433 151.006 36.1527C150.138 36.6631 149.147 36.9302 148.14 36.926C147.155 36.9314 146.189 36.6588 145.353 36.1393C144.447 35.5944 143.709 34.8101 143.22 33.8727C142.66 32.7758 142.38 31.5573 142.406 30.326C142.37 29.115 142.65 27.9154 143.22 26.846C143.585 26.1883 144.077 25.6097 144.667 25.1442C145.258 24.6786 145.936 24.3353 146.66 24.1342C147.385 23.9332 148.143 23.8784 148.889 23.9731C149.635 24.0679 150.355 24.3102 151.006 24.686C151.908 25.199 152.64 25.9635 153.113 26.886C153.675 27.977 153.95 29.1928 153.913 30.4193C153.95 31.6501 153.675 32.8703 153.113 33.966Z"
          fill={fillColor}
        />
        <path
          d="M173.581 38.1931L177.767 27.9397C177.801 27.8448 177.801 27.7413 177.767 27.6464L173.514 17.6331C173.453 17.462 173.341 17.3138 173.193 17.2087C173.046 17.1037 172.869 17.047 172.687 17.0464H165.607C165.405 17.0473 165.209 17.1127 165.047 17.2331C164.894 17.3523 164.783 17.5184 164.733 17.7061C164.682 17.8938 164.694 18.0928 164.767 18.2731L172.767 38.1931C172.767 38.3009 172.81 38.4043 172.886 38.4806C172.963 38.5569 173.066 38.5997 173.174 38.5997C173.282 38.5997 173.385 38.5569 173.461 38.4806C173.538 38.4043 173.581 38.3009 173.581 38.1931Z"
          fill={fillColor}
        />
        <path
          d="M16.0474 17.006H12.4874V16.1394C12.4874 14.9927 12.4874 11.246 13.1007 10.566C13.354 10.2594 14.0874 9.97935 15.7674 10.0194C16.0014 10.0202 16.2264 9.92928 16.394 9.76602C16.4798 9.68597 16.5478 9.58886 16.5937 9.48092C16.6397 9.37299 16.6625 9.25664 16.6607 9.13935V3.80602C16.6608 3.57718 16.5718 3.35729 16.4125 3.19301C16.2531 3.02872 16.0361 2.93295 15.8074 2.92602C12.0474 2.81935 9.15404 3.56602 7.24737 5.15269C5.3407 6.73935 4.3007 9.28602 4.3007 12.6327V17.0194H2.32737C2.21131 17.0176 2.09607 17.0391 1.98849 17.0827C1.88091 17.1263 1.78318 17.191 1.70111 17.2731C1.61903 17.3552 1.55428 17.4529 1.51069 17.5605C1.4671 17.6681 1.44557 17.7833 1.44737 17.8994V23.0994C1.44557 23.2154 1.4671 23.3307 1.51069 23.4382C1.55428 23.5458 1.61903 23.6435 1.70111 23.7256C1.78318 23.8077 1.88091 23.8724 1.98849 23.916C2.09607 23.9596 2.21131 23.9812 2.32737 23.9794H4.3007V42.966C4.2989 43.0821 4.32044 43.1973 4.36402 43.3049C4.40761 43.4125 4.47236 43.5102 4.55444 43.5923C4.63652 43.6744 4.73424 43.7391 4.84182 43.7827C4.9494 43.8263 5.06464 43.8478 5.1807 43.846H11.6074C11.7234 43.8478 11.8387 43.8263 11.9462 43.7827C12.0538 43.7391 12.1516 43.6744 12.2336 43.5923C12.3157 43.5102 12.3805 43.4125 12.424 43.3049C12.4676 43.1973 12.4892 43.0821 12.4874 42.966V23.966H16.0474C16.2808 23.966 16.5046 23.8733 16.6696 23.7083C16.8347 23.5432 16.9274 23.3194 16.9274 23.086V17.886C16.9274 17.6526 16.8347 17.4288 16.6696 17.2638C16.5046 17.0987 16.2808 17.006 16.0474 17.006Z"
          fill={fillColor}
        />
        <path
          d="M35.3933 16.6327C33.6641 16.6041 31.9579 17.0318 30.4467 17.8727C29.7715 18.2747 29.1451 18.7534 28.58 19.2994V17.886C28.58 17.6526 28.4873 17.4288 28.3223 17.2638C28.1572 17.0987 27.9334 17.006 27.7 17.006H21.38C21.1466 17.006 20.9228 17.0987 20.7577 17.2638C20.5927 17.4288 20.5 17.6526 20.5 17.886V42.966C20.5 43.1994 20.5927 43.4232 20.7577 43.5883C20.9228 43.7533 21.1466 43.846 21.38 43.846H27.7267C27.8422 43.846 27.9567 43.8233 28.0634 43.779C28.1702 43.7348 28.2672 43.67 28.3489 43.5883C28.4306 43.5066 28.4955 43.4095 28.5397 43.3028C28.5839 43.196 28.6067 43.0816 28.6067 42.966V30.4727C28.5209 29.72 28.5851 28.9577 28.7956 28.2299C29.0062 27.5021 29.3589 26.8233 29.8333 26.2327C30.9411 25.3632 32.3364 24.9441 33.74 25.0594H35.42C35.6534 25.0594 35.8772 24.9666 36.0423 24.8016C36.2073 24.6366 36.3 24.4127 36.3 24.1794V17.5127C36.302 17.3943 36.2796 17.2768 36.2344 17.1674C36.1892 17.058 36.122 16.959 36.0371 16.8765C35.9521 16.7941 35.8512 16.7299 35.7404 16.688C35.6297 16.646 35.5116 16.6272 35.3933 16.6327Z"
          fill={fillColor}
        />
        <path
          d="M51.0997 16.6328C44.4331 16.6328 38.1531 21.9661 38.1531 30.3128C38.0627 32.1283 38.3478 33.9429 38.9907 35.6432C39.6336 37.3435 40.6203 38.8928 41.8893 40.1943C43.1583 41.4958 44.6821 42.5215 46.3656 43.2072C48.049 43.8929 49.8559 44.2239 51.6731 44.1795C57.5931 44.1795 62.4331 40.0195 63.5397 36.2861C63.5852 36.1232 63.5907 35.9518 63.5559 35.7863C63.521 35.6208 63.4469 35.4661 63.3396 35.3353C63.2324 35.2045 63.0952 35.1015 62.9398 35.0349C62.7843 34.9683 62.6151 34.9402 62.4464 34.9528H57.1131C56.8402 34.9644 56.5746 35.0451 56.3414 35.1874C56.1082 35.3296 55.915 35.5288 55.7797 35.7661C55.3111 36.3621 54.697 36.8272 53.9963 37.1168C53.2957 37.4064 52.5323 37.5106 51.7797 37.4195C50.3418 37.4993 48.9283 37.0235 47.8314 36.0904C46.7344 35.1572 46.0381 33.8382 45.8864 32.4061H63.0197C63.2723 32.4208 63.5204 32.3352 63.7101 32.1679C63.8999 32.0006 64.0159 31.7652 64.0331 31.5128C64.0331 31.0595 64.0331 30.4995 64.0331 29.9528C64.0464 21.6328 59.1397 16.6328 51.0997 16.6328ZM46.0331 27.2995C46.1741 26.0468 46.788 24.8952 47.7495 24.0799C48.711 23.2646 49.9475 22.8471 51.2064 22.9128C54.9664 22.9128 56.3131 25.3128 56.4197 27.2995H46.0331Z"
          fill={fillColor}
        />
        <path
          d="M80.4338 16.6328C73.7671 16.6328 67.5004 21.9661 67.5004 30.3128C67.4081 32.1276 67.6915 33.942 68.3329 35.6422C68.9742 37.3425 69.9597 38.892 71.2277 40.1937C72.4956 41.4954 74.0186 42.5213 75.7014 43.2072C77.3842 43.893 79.1905 44.224 81.0071 44.1795C86.9404 44.1795 91.7671 40.0195 92.8738 36.2861C92.9192 36.1232 92.9247 35.9518 92.8899 35.7863C92.8551 35.6208 92.7809 35.4661 92.6737 35.3353C92.5664 35.2045 92.4293 35.1015 92.2738 35.0349C92.1183 34.9683 91.9491 34.9402 91.7804 34.9528H86.4471C86.1739 34.9632 85.9079 35.0434 85.6745 35.1857C85.441 35.3281 85.248 35.5279 85.1138 35.7661C84.6426 36.3591 84.0281 36.8221 83.3281 37.1114C82.6282 37.4007 81.8661 37.5068 81.1138 37.4195C79.6759 37.4993 78.2623 37.0235 77.1654 36.0904C76.0685 35.1572 75.3722 33.8382 75.2204 32.4061H92.3671C92.6196 32.4208 92.8677 32.3352 93.0575 32.1679C93.2472 32.0006 93.3633 31.7652 93.3804 31.5128C93.3804 31.0595 93.3804 30.4995 93.3804 29.9528C93.3804 21.6328 88.4738 16.6328 80.4338 16.6328ZM75.3804 27.2995C75.5183 26.0479 76.1295 24.8964 77.0888 24.0808C78.0481 23.2653 79.283 22.8474 80.5404 22.9128C84.3004 22.9128 85.6604 25.3128 85.7671 27.2995H75.3804Z"
          fill={fillColor}
        />
        <path
          d="M197.887 7.97921L198.42 6.64588C198.42 6.64588 199.193 5.03255 198.42 3.97921C198.07 3.30744 197.514 2.76542 196.833 2.43255L194.513 1.51255C193.781 1.3076 193.003 1.33555 192.287 1.59255C191.073 1.87255 190.553 3.53921 190.553 3.53921L190.007 4.87255C189.749 4.83313 189.485 4.87023 189.248 4.97925C189.011 5.08827 188.811 5.26442 188.673 5.48588L189.633 5.84588L187.807 10.5792C187.647 10.9925 186.98 13.0192 186.98 13.0192C186.98 13.0192 186.713 14.1792 186.58 14.7125C186.466 14.9898 186.426 15.2923 186.466 15.5896C186.506 15.8869 186.623 16.1684 186.807 16.4059C188.413 17.7697 190.434 18.5498 192.54 18.6192C192.84 18.5745 193.123 18.4492 193.358 18.2567C193.592 18.0641 193.771 17.8116 193.873 17.5259L194.713 15.9925C194.713 15.9925 195.593 14.0459 195.753 13.6325C195.913 13.2192 196.78 10.8859 197.553 8.89921L198.527 9.27255C198.713 8.75255 198.02 8.04588 197.887 7.97921ZM193.073 17.3125C192.073 17.327 191.08 17.1411 190.153 16.7659L189.873 16.6592C188.937 16.3121 188.077 15.7868 187.34 15.1125L188.3 12.9659C189.034 13.5641 189.876 14.0167 190.78 14.2992L191.06 14.4059C191.92 14.8018 192.848 15.0282 193.793 15.0725L193.073 17.3125ZM197.38 7.64588L196.327 9.19255C195.49 8.65134 194.595 8.20419 193.66 7.85921L193.393 7.76588C192.453 7.39105 191.474 7.12271 190.473 6.96588L190.74 5.12588C190.74 5.12588 190.873 4.36588 192.207 4.49921C192.915 4.55142 193.611 4.7087 194.273 4.96588L194.887 5.19255C195.547 5.45385 196.167 5.80858 196.727 6.24588C197.727 7.00588 197.38 7.64588 197.38 7.64588Z"
          fill={fillColor}
        />
        <path
          d="M191.194 21.1127C191.072 21.0561 190.94 21.025 190.805 21.0214C190.671 21.0177 190.537 21.0416 190.412 21.0916C190.287 21.1415 190.174 21.2165 190.079 21.3118C189.984 21.4072 189.91 21.5209 189.861 21.646L176.527 53.7527C176.471 53.8747 176.44 54.0069 176.436 54.1414C176.432 54.2758 176.456 54.4096 176.506 54.5344C176.556 54.6593 176.631 54.7727 176.726 54.8675C176.822 54.9624 176.935 55.0367 177.061 55.086C177.178 55.1378 177.305 55.165 177.434 55.166C177.631 55.1656 177.823 55.1072 177.986 54.9981C178.15 54.889 178.278 54.734 178.354 54.5527L191.687 22.446C191.745 22.3265 191.778 22.1966 191.784 22.064C191.791 21.9314 191.77 21.7989 191.724 21.6744C191.678 21.5499 191.607 21.436 191.516 21.3395C191.425 21.243 191.316 21.1658 191.194 21.1127Z"
          fill={fillColor}
        />
        <path
          d="M185.7 18.6195C185.578 18.5644 185.447 18.5342 185.314 18.5306C185.18 18.5271 185.048 18.5502 184.924 18.5986C184.799 18.6471 184.686 18.7199 184.59 18.8128C184.495 18.9056 184.419 19.0167 184.366 19.1395L169.38 54.5128C169.323 54.6348 169.292 54.7671 169.288 54.9015C169.285 55.036 169.309 55.1697 169.359 55.2946C169.409 55.4195 169.484 55.5328 169.579 55.6276C169.674 55.7225 169.788 55.7969 169.913 55.8461C170.035 55.8993 170.167 55.9266 170.3 55.9262C170.493 55.9259 170.682 55.869 170.843 55.7623C171.004 55.6556 171.131 55.504 171.206 55.3261L186.22 19.9528C186.277 19.8316 186.308 19.7002 186.313 19.5664C186.318 19.4325 186.295 19.2992 186.246 19.1745C186.197 19.0497 186.124 18.9362 186.03 18.8408C185.936 18.7454 185.824 18.6701 185.7 18.6195Z"
          fill={fillColor}
        />
      </svg>
    </Box>
  );
}
