export enum BasePaths {
  Base = "/",
  Home = "/home",
  OldHome = "/app",
  Announcements = "/announcements",
  VehicleValuation = "/vehiclevaluation",
  AutoFinderPlus = "/autofinderplus",
  NotFound = "/404",
  Error = "/error",
  Login = "/login",
  Unauthorized = "/unauthorized",
  Announcement = "/announcements/:id",
}

// Domain specific paths can be added to the AppPaths, or additional enums can be
// created for each domain - creating a hierarchy of routes, with sub-routes.

export enum AppPaths {
  App = "/app",
  RoleProtectedApp = "/dashboard/role-protected-app",
}

export default {
  ...BasePaths,
  ...AppPaths,
};
