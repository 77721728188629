import useAuth from "hooks/useAuth";
import { useMemo } from "react";

type ClaimsWithGroups = {
  groups: string[];
};

export default function useMsalGroups(): Set<string> {
  const { accountInfo } = useAuth();
  const { idTokenClaims } = accountInfo;

  return useMemo(
    () =>
      idTokenClaims && hasGroups(idTokenClaims)
        ? new Set(idTokenClaims.groups)
        : new Set(),
    [idTokenClaims],
  );
}

function hasGroups(claims: object): claims is ClaimsWithGroups {
  return (
    Boolean((claims as ClaimsWithGroups).groups) &&
    Array.isArray((claims as ClaimsWithGroups).groups)
  );
}
