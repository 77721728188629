import { useIsAuthenticated as realUseIsAuthenticated } from "@azure/msal-react";
import useLocalStorage from "hooks/useLocalStorage";

const useMockIsAuthenticated = () => {
  const [isAuthenticated] = useLocalStorage("isAuthenticated", true);
  return isAuthenticated;
};

export const useIsAuthenticated = process.env["REACT_APP_MOCK_FOR_INTEGRATION"]
  ? useMockIsAuthenticated
  : realUseIsAuthenticated;
